import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"

const ThankYou = () => {
  const [key, setKey] = useState(undefined)

  useEffect(() => {
    setKey(localStorage.getItem("thankload"))
  }, [])
  if (key === "1") {
    window.location.reload(true)
    localStorage.setItem("thankload", "0")
  }
  useEffect(() => {
    // Add the async gtag script
    const gtagScript = document.createElement("script")
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-823316446"
    gtagScript.async = true
    document.head.appendChild(gtagScript)

    // Add the gtag initialization script
    const inlineScript = document.createElement("script")
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-823316446');
      gtag('event', 'conversion', {
          'send_to': 'AW-823316446/TuwGCNuouvAZEN6fy4gD',
          'value': 1.0,
          'currency': 'JPY'
      });
    `
    document.head.appendChild(inlineScript)

    return () => {
      // Cleanup the scripts when the component unmounts
      document.head.removeChild(gtagScript)
      document.head.removeChild(inlineScript)
    }
  }, [])
  useEffect(() => {
    // Add the Facebook Pixel main script
    const fbqScript = document.createElement("script")
    fbqScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '589949796691668');
      fbq('track', 'PageView');
      fbq('track', 'CompleteRegistration');
    `
    document.head.appendChild(fbqScript)

    // Add the noscript fallback
    const noscript = document.createElement("noscript")
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=589949796691668&ev=PageView&noscript=1" />
    `
    document.body.appendChild(noscript)

    return () => {
      // Cleanup the added scripts when the component unmounts
      document.head.removeChild(fbqScript)
      document.body.removeChild(noscript)
    }
  }, [])
  return (
    <section className="container about">
      <div className="row">
        <div className="col-lg-9 col-lg-9">
          <div className="about__company-profile">
            <SectionTitle>お問い合わせありがとうございました。</SectionTitle>
            <hr className="u-line-bottom" />
          </div>
          <div className="container-full u-margin-bottom-2">
            <div className="row no-gutters">
              <p className="u-size-1 u-margin-bottom-1 u-margin-top-1 u-line-height">
                この度は、クイックグローバルメキシコの求人へお問い合わせ・お申し込み頂きありがとうございます。
                自動送信のメールにてご連絡させていただきましたので、ご確認ください。
              </p>
              <p className="u-size-0-8 u-margin-bottom-1 u-margin-top-1 u-line-height">
                ※もし数分経っても届かない場合は、お手数ですが直接メールをいただけますようお願い致します。迷惑メールフォルダに入ってしまう場合もありますので、その際はそちらもご確認ください。
                メールアドレス：
                <a
                  className="u-color-danger"
                  href="maito:quick@919mexico.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  quick@919mexico.com
                </a>
              </p>
              <p className="u-margin-bottom-1 u-margin-top-1 u-line-height">
                また、
                <Link className="u-color-danger" to="/process">
                  お申し込みの流れ（サポートの流れ）
                </Link>
                から今後の流れをご確認いただけます。
              </p>
              <p className="u-margin-bottom-1 u-margin-top-1 u-line-height">
                QUICK GLOBAL MEXICO, S.A. DE C.V. <br />
                住所：C.C. Punto 45, Boulevard A Zacatecas 845, <br />
                Oficina 510, Desarrollo Especial Bulevar a Zacatecas,
                <br />
                C.P.20116 Aguascalientes, Ags.
                <br />
                電話番号：+52-449-107-5308
                <br />
                Eメール：quick@919mexico.com
                <br />
                営業時間：平日9：00～18：00（メキシコ中部時間）
                <br />
              </p>
            </div>
          </div>
          <Contact lang={"ja"} />
        </div>
        <WrapperRight />
      </div>
    </section>
  )
}

export default ThankYou

export const Head = () => {
  return (
    <Seo
      title="お問い合わせありがとうございました。"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/thankyou`}
    />
  )
}
